<template>
  <div class="download-container">
    <img v-if="!isMobile" alt="demo" src="../assets/img/app_screenshot.png" width="368" class="heightAuto" />
    <div class="ml-2">
      <img alt="logo" src="../assets/img/logo_tailoc.png" width="190" class="heightAuto" />
      <h4 class="text-uppercase font-weight-600 text-white mt-4 text-shadow">ỨNG DỤNG MUA HỘ</h4>
      <h1 class="text-uppercase font-weight-600 text-white mt-2 mb-4 text-shadow">VÉ SỐ VIETLOTT</h1>
      <div v-for="(text, index) in descriptions" v-bind:key="index" class="description mb-2">
        <img alt="icon" width="15" src="../assets/img/icon_star.png" />
        <span class="ml-2">{{ text }}</span>
      </div>
      <div class="mt-4">
        <a class="store-btn" @click="openDownloadUrl('ios')" target="_blank">
          <img alt="img" src="../assets/img/app-store.png" width="148" class="heightAuto max-width-100" />
        </a>
        <a class="store-btn ml-2" @click="openDownloadUrl('android')" target="_blank">
          <img alt="img" src="../assets/img/google-play.png" width="148" class="heightAuto max-width-100" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { isIOS, isMobile } from 'mobile-device-detect';

export default {
  data() {
    return {
      isMobile,
      descriptions: [
        'Mua nhanh thắng lớn',
        'Thanh toán đơn giản',
        'An toàn & bảo mật',
        'Cập nhật kết quả trực tuyến',
        'Tra kết quả các vé đã mua'
      ]
    };
  },
  created() {
    this.redirectToStore();
  },
  methods: {
    openDownloadUrl(platform) {
      if (platform === 'android') {
        this.openDeepLink(this.constants.CH_PLAY_DOWNLOAD_URL, '_blank');
      } else {
        this.openDeepLink(this.constants.APP_STORE_DOWNLOAD_URL, '_blank');
      }
    },
    redirectToStore() {
      if (isMobile) {
        this.openDeepLink(
          this.generateDeepLink(isIOS ? this.constants.APP_STORE_DOWNLOAD_URL : this.constants.CH_PLAY_DOWNLOAD_URL)
        );
        this.$router.push('/');
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.store-btn {
  cursor: pointer;
}
.text-shadow {
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.download-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../assets/img/background_download.jpg');
  background-size: cover;
  background-position: top center;
  height: 100vh;
  flex: 1;
}
.max-width-100 {
  max-width: 100%;
}
.description {
  display: flex;
  align-items: center;
  flex-direction: row;
  span {
    color: #ffffff;
    font-size: 18px;
    line-height: 1.94;
  }
}
</style>
